































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  name: 'datePicker'
})
export default class DatePicker extends Vue {
  @Prop({ default: () => {} }) private setDate: any[]
  @Prop({ default: '' }) private pickType: string
  @Prop({ default: false }) private isLockPassenger: boolean
  @Prop({ default: false }) private detailPickerLock: boolean
  @Prop({ default: false }) private isLockDataPick: boolean
  @Prop({ default: false }) private isLockPerson: boolean
  @Prop({ default: false }) private isLockPass: boolean
  @Prop({ default: true }) private shortcut: boolean
  @Prop({ default: false }) private isLockVerification: boolean
  @Prop({ default: false }) private isLockPassFailed: boolean
  @Prop({ default: false }) private isLockInfoCollection: boolean

  @Prop({
    default: () => ({
      disabledDate: (date: Date) => moment(date).valueOf() > moment().endOf('day').valueOf()
    })
  })
  private pickerOptions: any

  private dateRange = ['今天', '近7天', '近30天']
  private selectIndex = 0
  private dateValue: Date[] = []
  private isClear = false
  private DateCycle: number | string = 'null'
  private currentAccount: string | null = ''
  private currentStatus: AnyObj = {}

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  created() {
    this.dateValue = [
      new Date(moment().startOf('day').valueOf()),
      new Date(moment().endOf('day').valueOf())
    ]
  }
  mounted() {
    this.currentAccount = localStorage.getItem('currentAccount')
    if (this.currentAccount) {
      const currentAccount = this.isRootAccount
        ? this.currentAccount
        : this.currentAccount + '-area'
      this.currentStatus = JSON.parse(localStorage.getItem(currentAccount) as string)
    }
    if (this.currentStatus && this.currentStatus.DateCycle) {
      this.DateCycle = this.currentStatus.DateCycle
    } else if (!this.currentStatus) {
      this.currentStatus = {}
    }
    if (
      this.pickType === 'passenger' &&
      this.currentStatus &&
      this.currentStatus.DateCyclePassenger
    ) {
      this.DateCycle = this.currentStatus.DateCyclePassenger
    }
    if (
      this.pickType === 'detailPassenger' &&
      this.currentStatus &&
      this.currentStatus.DetailDateCyclePassenger
    ) {
      this.DateCycle = this.currentStatus.DetailDateCyclePassenger
    }

    if (
      this.pickType === 'personSearch' &&
      this.currentStatus &&
      this.currentStatus.dataCyclePersonsearch
    ) {
      this.DateCycle = this.currentStatus.dataCyclePersonsearch
    }
    if (
      this.pickType === 'infoCollection' &&
      this.currentStatus &&
      this.currentStatus.dataCycleInfoCollection
    ) {
      this.DateCycle = this.currentStatus.dataCycleInfoCollection
    }

    this.handelDatePicker()
  }

  @Watch('setDate')
  watchSetDate() {
    this.dateValue = this.setDate
    this.DateCycle = this.dateQuickSelec()
    this.handelDatePicker('change')
  }

  private clickPicker(d: number) {
    // 这两个判断是如果锁定状态为锁定，则该事件不生效，若其他组件引用则需加一个判断
    if (this.isLockPassenger && this.pickType === 'passenger') {
      return
    }

    if (this.isLockDataPick && this.pickType === 'dataMap') {
      return
    }

    if (this.isLockPerson && this.pickType === 'personSearch') {
      return
    }

    if (this.isLockPass && this.pickType === 'passDetail') {
      return
    }

    if (this.isLockVerification && this.pickType === 'verification') {
      return
    }
    if (this.isLockPassFailed && this.pickType === 'passFailed') {
      return
    }

    if (this.detailPickerLock && this.pickType === 'detailPassenger') {
      return
    }
    if (this.isLockInfoCollection && this.pickType === 'infoCollection') {
      return
    }

    this.selectIndex = d
    if (d === 0) {
      this.DateCycle = 1
      const start = new Date(moment().startOf('day').valueOf())
      const end = new Date(moment().endOf('day').valueOf())
      this.dateValue = [start, end]
    }
    if (d === 1) {
      this.DateCycle = 7
      const start = new Date(moment().add(-6, 'd').startOf('day').valueOf())
      const end = new Date(moment().endOf('day').valueOf())
      this.dateValue = [start, end]
    }
    if (d === 2) {
      this.DateCycle = 30
      const start = new Date(moment().add(-29, 'd').startOf('day').valueOf())
      const end = new Date(moment().endOf('day').valueOf())
      this.dateValue = [start, end]
    }
    this.handelDatePicker()

    this.$emit('change', this.dateValue, this.DateCycle)
    this.$emit('filter')
  }
  private getTimes(time: any) {
    // 获取时间戳
    return moment(time).format('MM/DD')
  }
  private handelDatePicker(type?: string) {
    if (this.dateValue === null) return
    if (type !== undefined && type === 'change') {
      this.selectIndex = -1
      const value = this.getTimes(this.dateValue[0])
      const value1 = this.getTimes(this.dateValue[1])
      const start = this.getTimes(moment().startOf('day'))
      const start1 = this.getTimes(moment().add(-6, 'd'))
      const start2 = this.getTimes(moment().add(-29, 'd'))
      if (value1 === start && this.DateCycle !== 'null') {
        let flag = false
        if (value === start) {
          this.selectIndex = 0
          flag = true
        }
        if (value === start1) {
          this.selectIndex = 1
          flag = true
        }
        if (value === start2) {
          this.selectIndex = 2
          flag = true
        }
        if (!flag) {
          this.selectIndex = -1
        }
      } else {
        this.selectIndex = -1
      }
    }

    this.$emit('change', this.dateValue, this.DateCycle)
  }
  private changeValue() {
    this.DateCycle = 'null'
    this.selectIndex = -1

    this.$emit('change', this.dateValue, this.DateCycle)
    this.$emit('filter')
  }
  // 判断是当前时间检索属于哪个页面
  public judge() {
    this.currentAccount = localStorage.getItem('currentAccount') // 拿到当前登陆账户的用户名
    if (this.currentAccount) {
      const currentAccount = this.isRootAccount
        ? this.currentAccount
        : this.currentAccount + '-area'
      this.currentStatus = JSON.parse(localStorage.getItem(currentAccount) as string) || {} // 拿到当前账户下所有的数据
      if (this.pickType === 'dataMap') {
        Object.assign(this.currentStatus, { DateCycle: this.DateCycle })
      }
      if (this.pickType === 'passenger') {
        Object.assign(this.currentStatus, { DateCyclePassenger: this.DateCycle })
      }
      if (this.pickType === 'detailPassenger') {
        Object.assign(this.currentStatus, { DetailDateCyclePassenger: this.DateCycle })
      }
      if (this.pickType === 'personSearch') {
        Object.assign(this.currentStatus, { dataCyclePersonsearch: this.DateCycle })
      }
      if (this.pickType === 'passDetail') {
        Object.assign(this.currentStatus, { dataCyclePassDetail: this.DateCycle })
      }
      if (this.pickType === 'verification') {
        Object.assign(this.currentStatus, { dataCycleVerification: this.DateCycle })
      }
      if (this.pickType === 'infoCollection') {
        Object.assign(this.currentStatus, { dataCycleInfoCollection: this.DateCycle })
      }
      localStorage.setItem(currentAccount, JSON.stringify(this.currentStatus))
      this.currentStatus = JSON.parse(localStorage.getItem(this.currentAccount) as string) || {} // 拿到当前账户下所有的数据
    }
  }
  // 拿到最新的日期快捷选择
  private dateQuickSelec() {
    this.currentAccount = localStorage.getItem('currentAccount') // 拿到当前登陆账户的用户名
    if (this.currentAccount) {
      const currentAccount = this.isRootAccount
        ? this.currentAccount
        : this.currentAccount + '-area'
      this.currentStatus = JSON.parse(localStorage.getItem(currentAccount) as string) || {} // 拿到当前账户下所有的数据
    }
    if (this.pickType === 'dataMap') {
      return this.currentStatus.DateCycle || 1
    } else if (this.pickType === 'passenger') {
      return this.currentStatus.DateCyclePassenger || 1
    } else if (this.pickType === 'detailPassenger') {
      return this.currentStatus.DetailDateCyclePassenger || 1
    } else if (this.pickType === 'personSearch') {
      return this.currentStatus.dataCyclePersonsearch || 1
    } else if (this.pickType === 'passDetail') {
      return this.currentStatus.dataCyclePassDetail || 1
    } else if (this.pickType === 'verification') {
      return this.currentStatus.dataCycleVerification || 1
    } else if (this.pickType === 'infoCollection') {
      return this.currentStatus.dataCycleInfoCollection || 1
    } else {
      return 1
    }
  }
  get isLock() {
    // 若再有其他组件引用则需要添加到else if中
    if (this.pickType === 'dataMap') {
      return this.isLockDataPick
    } else if (this.pickType === 'passenger') {
      return this.isLockPassenger
    } else if (this.pickType === 'detailPassenger') {
      return this.detailPickerLock
    } else if (this.pickType === 'personSearch') {
      return this.isLockPerson
    } else if (this.pickType === 'passDetail') {
      return this.isLockPass
    } else if (this.pickType === 'verification') {
      return this.isLockVerification
    } else if (this.pickType === 'passFailed') {
      return this.isLockPassFailed
    } else if (this.pickType === 'infoCollection') {
      return this.isLockInfoCollection
    } else {
      return false
    }
  }
}
